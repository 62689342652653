import { useQuery } from "@apollo/client";
import { revenuePie, revenueReportClassofBusiness } from "../graphql/queries/offers";
import { RevenueReportApi, RevenueReportApiVariables } from "graphql/queries/__generated__/RevenueReportApi";
import numeral from "numeral";
import { RevenueReportClassofBusiness, RevenueReportClassofBusinessVariables } from "graphql/queries/__generated__/RevenueReportClassofBusiness";

export const useRevenueBar = (variables: RevenueReportApiVariables) => {
  const { data, ...rest } =
    useQuery<RevenueReportApi, RevenueReportApiVariables>(revenuePie, {
      variables,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    });

  return {
    data: data?.revenueReportApi?.map(el => ({
      ...el,
      visalre: numeral(el?.visalre || "0").value(),
      kekre: numeral(el?.kekre || "0").value(),
      iriskre: numeral(el?.iriskre || "0").value(),
    })) || [],
    ...rest
  };
};
export const useRevenueByClassOFBusiness = (variables: RevenueReportApiVariables) => {
  const { data, ...rest } =
    useQuery<RevenueReportClassofBusiness, RevenueReportClassofBusinessVariables>(revenueReportClassofBusiness, {
      variables,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    });

  return {
    data: data?.revenueReportClassofBusiness?.map(el => ({
      ...el,
      visalre: numeral(el?.visalre || "0").value(),
      kekre: numeral(el?.kekre || "0").value(),
      iriskre: numeral(el?.iriskre || "0").value(),
    })) || [],
    ...rest
  };
};
