import { DocumentIcon } from "@heroicons/react/24/outline";
import { FetchOffers_fetchOffers_data } from "../../graphql/queries/__generated__/FetchOffers";
import OffersActionMenu from "./components/OffersActionMenu";
import { Badge } from "@mantine/core";
import ClaimsActions from "./components/ClaimsActions";
import numeral from "numeral";

export const columns = [
  {
    accessor: "",
    name: "Policy Number | Insured",
    render: (offer: FetchOffers_fetchOffers_data | null) => (
      <div className="flex items-center max-w-md ">
        <div className="h-10 w-10 flex-shrink-0">
          <DocumentIcon className="h-10 w-10 rounded-full" />
        </div>
        <div className="ml-4 break-all pl-3 truncate text-ellipsis">
          <div className="font-medium truncate text-gray-900">
            {offer?.offer_detail?.insured_by}
          </div>
          <div className="text-gray-500">
            {offer?.offer_detail?.policy_number}
          </div>
        </div>
      </div>
    ),
  },
  {
    accessor: "",
    name: "Policy Period",
    render: (offer: FetchOffers_fetchOffers_data | null) => (
      <div className="flex items-center">
        <div className="">
          <div className="font-medium text-green-900">
            {offer?.offer_detail?.period_of_insurance_from}
          </div>
          <div className="text-red-500">
            {offer?.offer_detail?.period_of_insurance_to}
          </div>
        </div>
      </div>
    ),
  },
  {
    accessor: "",
    name: "Currency | Amount",
    render: (offer: FetchOffers_fetchOffers_data | null) => (
      <div className="flex items-center max-w-md">
        <div className="break-all pl-3 truncate text-ellipsis">
          <div className="font-medium truncate text-gray-900">
            {offer?.offer_detail?.currency}
          </div>
          <div className="text-gray-500">
            {numeral(offer?.offer_amount).format("#,#.##")}
          </div>
        </div>
      </div>
    ),
  },
  {
    accessor: "",
    name: "Status",
    render: (offer: FetchOffers_fetchOffers_data | null) => (
      <div className="flex items-center">
        <Badge
          color={offer?.offer?.expiry_status === "EXPIRED" ? "red" : "teal"}
        >
          {offer?.offer?.expiry_status}
        </Badge>
      </div>
    ),
  },
  {
    accessor: "",
    name: "Payment Status",
    render: (offer: FetchOffers_fetchOffers_data | null) => (
      <div className="flex items-center">
        <Badge
          color={
            offer?.offer?.payment_status === "PAID"
              ? "blue"
              : offer?.offer?.payment_status === "PARTPAYMENT"
              ? "yellow"
              : "red"
          }
        >
          {offer?.offer?.payment_status}
        </Badge>
      </div>
    ),
  },
  {
    accessor: "",
    name: "Action",
    render: (offer: FetchOffers_fetchOffers_data | null) => (
      <OffersActionMenu offer={offer} />
    ),
  },
];

export const claimColumns = [
  {
    accessor: "claim_amount",
    name: "Claim Amount (100%)",
    render: (offer: any) => (
      <div className="flex items-center">
        {numeral(offer?.claim_amount)?.format("#,#.##")}
      </div>
    ),
  },
  {
    accessor: "offer_participant_percentage",
    name: "Participating %",
    // render: (offer: FetchOffers_fetchOffers_data_offer_offer_claim | null) => (
    //   <div className="flex items-center"></div>
    // ),
  },
  {
    accessor: "claim_share",
    name: "Claim Share",
  },
  {
    accessor: "claim_date",
    name: "Claim Date",
    // render: (offer: FetchOffers_fetchOffers_data_offer_offer_claim | null) => (
    //   <div className="flex items-center"></div>
    // ),
  },
  {
    accessor: "claim_amount",
    name: "Actions",
    render: (offer: any) => <ClaimsActions item={offer} />,
  },
];
