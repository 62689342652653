import { Navigate, Outlet, Route } from "react-location";

import {
  ChartBarSquareIcon, CogIcon, FolderIcon, HomeIcon,
} from "@heroicons/react/24/outline";
import { AllOffersPage, AllTreatiesPage, DashboardPage, LoginPage, ReportsPage, SettingsPage } from "../pages";

export type RouteProps = Omit<Route, "children"> & {
  // withPermissions?: UserPermission[];
  navigation?: boolean;
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  {
    path: "/app/dashboard",
    element: <Outlet />,
    sidebar: {
      label: "Dashboard",
      icon: HomeIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Dashboard",
      section: "General",
    },
    children: [
      {
        path: "/",
        element: <DashboardPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
    ],
  },
  {
    path: "app/offers",
    element: <Outlet />,
    sidebar: {
      label: "All Offers",
      icon: FolderIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "All Offers",
      section: "General",
    },
    children: [
      {
        path: "/",
        element: <AllOffersPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
    ],
  },
  {
    path: "app/treaties",
    element: <Outlet />,
    sidebar: {
      label: "All Treaties",
      icon: FolderIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "All Treaties",
      section: "General",
    },
    children: [
      {
        path: "/",
        element: <AllTreatiesPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
    ],
  },
  {
    path: "app/reports",
    element: <Outlet />,
    sidebar: {
      label: "Reports",
      icon: FolderIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Reports",
      section: "General",
    },
    children: [
      {
        path: "/",
        element: <ReportsPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
    ],
  },
  {
    path: "app/settings",
    element: <Outlet />,
    sidebar: {
      label: "Settings",
      icon: CogIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Settings",
      section: "General",
    },
    children: [
      {
        path: "/",
        element: <SettingsPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to="/cpanel-login" />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Auth",
    }
  },
  {
    path: "cpanel-login",
    element: <LoginPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Auth",
    },
  },
];

export default routes;
