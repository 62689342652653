import Root from "./Root";
import LayoutProvider from "./layouts";
import RoutesProvider from "router";

type Props = {};

function App(props: Props) {
  return (
    <Root>
      <RoutesProvider >
        <LayoutProvider />
      </RoutesProvider>
    </Root>
  );
}

export default App;
