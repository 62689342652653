import { DocumentIcon } from "@heroicons/react/24/outline";
import { FetchTreaties_fetchTreaties_data } from "../../graphql/queries/__generated__/FetchTreaties";
import TreatyActionsMenu from "./components/TreatyActionsMenu";
import { clsx } from "@mantine/core";

export const columns = [
  {
    accessor: "",
    name: "REF# | Treaty Name",
    render: (offer: FetchTreaties_fetchTreaties_data | null) => (
      <div className="flex items-center max-w-md ">
        <div className="h-10 w-10 flex-shrink-0">
          <DocumentIcon className="h-10 w-10 rounded-full" />
        </div>
        <div className="ml-4 break-all pl-3 truncate text-ellipsis">
          <div className="font-medium truncate text-gray-900">
            {offer?.treaty?.treaty_reference} -{" "}
            <span
              className={clsx("text-[10px]", {
                "text-sky-600":
                  offer?.treaty?.treaty_program?.treaty_type === "PROPORTIONAL",
                "text-teal-600":
                  offer?.treaty?.treaty_program?.treaty_type !== "PROPORTIONAL",
              })}
            >
              {offer?.treaty?.treaty_program?.treaty_type}
            </span>
          </div>
          <div className="text-gray-500">
            {offer?.treaty?.treaty_program?.treaty_name}
          </div>
        </div>
      </div>
    ),
  },
  {
    accessor: "",
    name: "Treaty Period",
    render: (offer: FetchTreaties_fetchTreaties_data | null) => (
      <div className="flex items-center">
        <div className="">
          <div className="font-medium text-green-900">
            {offer?.treaty?.treaty_deduction?.treaty_period_from}
          </div>
          <div className="text-red-500">
            {offer?.treaty?.treaty_deduction?.treaty_period_to}
          </div>
        </div>
      </div>
    ),
  },
  {
    accessor: "",
    name: "Currency | Reinsured",
    render: (offer: FetchTreaties_fetchTreaties_data | null) => (
      <div className="flex items-center max-w-md">
        <div className="break-all pl-3 truncate text-ellipsis">
          <div className="font-medium truncate text-gray-900">
            {offer?.treaty?.currency}
          </div>
          <div className="text-gray-500">
            {offer?.treaty?.insurer?.insurer_company_name}
          </div>
        </div>
      </div>
    ),
  },
  {
    accessor: "",
    name: "Treaty share ",
    render: (offer: FetchTreaties_fetchTreaties_data | null) => (
      <div className="flex items-center">
        <div className="">
          <div className="text-green-500">
            {offer?.treaty_participation_percentage ?? "N/A"}%
          </div>
        </div>
      </div>
    ),
  },
  {
    accessor: "",
    name: "Action",
    render: (offer: FetchTreaties_fetchTreaties_data | null) => (
      <TreatyActionsMenu treaty={offer} />
    ),
  },
];
