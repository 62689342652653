import { useTitle } from "react-use";
import ReactGA from "react-ga";


export const usePage = (title: string) => {
    useTitle(title, {
        restoreOnUnmount: true,
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
    return null
}