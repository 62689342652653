import { FC, SetStateAction, useState } from "react";
import PageHeader from "../../components/page-header";
import PageWrapper from "../../components/page-wrapper";
import TableWithCheckbox from "../../components/TableWithCheckbox/TableWithCheckbox";
import YearTabs from "../../components/year-tabs";
import { BrokerTypes } from "../../graphql/__generated__/globalTypes";
import { useTreaties } from "../../hooks/useTreaties";
import { TableData } from "../../utils/types";
import Overview from "../AllOffers/components/Overview";
import { columns } from "./columns";
import { Pagination } from "@mantine/core";
import { useAppSelector } from "../../app/hooks";
import { usePage } from "hooks/usePage";

type Props = {};

const AllTreaties: FC<Props> = (props) => {
  usePage("Kava | All Treaties");
  const [year, setYear] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const { broker } = useAppSelector((state) => state.offers);
  const [activeTab, setActiveTab] = useState<BrokerTypes>(BrokerTypes.VISAL);
  const {
    loading,
    treaties,
    total_treaties,
    total_shares,
    error,
    refetch,
    from,
    to,
    last_page,
  } = useTreaties({
    broker: broker as BrokerTypes,
    paginateTo: page,
    perPage,
  });
  return (
    <PageWrapper>
      <PageHeader title="Treaties" />
      <Overview
        stats={[
          {
            name: "Total Treaties",
            value: total_treaties ?? 0,
          },
          ...(total_shares
            ? Object.keys(total_shares).map((e) => ({
              name: `Total ${e}`,
              value: `${total_shares[e]}`,
            }))
            : []),
        ]}
      />
      <YearTabs
        {...{ year, setYear, activeTab }}
        setActiveTab={(value) => {
          setActiveTab(value);
          setPage(1);
        }}
      />
      <section className="mt-8 " aria-labelledby="gallery-heading">
        <TableWithCheckbox
          columns={columns}
          data={treaties}
          setSelected={function (value: SetStateAction<TableData<any>>): void {
            throw new Error("Function not implemented.");
          }}
          selected={[]}
          loading={loading}
          error={error}
          setNumberOfRows={setPerPage}
          numberOfRows={perPage}
          refresh={refetch}
        />
      </section>
      <section className="p-3 flex justify-between items-center">
        <span>
          Showing {from} to {to} of {total_treaties} items
        </span>
        <Pagination total={last_page} page={page} onChange={setPage} />
      </section>
    </PageWrapper>
  );
};

export default AllTreaties;
