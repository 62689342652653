import React, { Fragment } from "react";
import { useActivities } from "../../hooks/useAcivities";
import TableWithCheckbox from "../../components/TableWithCheckbox/TableWithCheckbox";
import { TableData } from "../../utils/types";
import { SeeClientActivites_seeClientActivites } from "../../graphql/queries/__generated__/SeeClientActivites";
import { usePage } from "hooks/usePage";

type Props = {};

const Activities = (props: Props) => {
  usePage("Kava | Activities");
  const { activities, loading, error } = useActivities();
  return (
    <Fragment>
      <section aria-labelledby="plan-heading w-full">
        <div className="px-4 sm:px-6 lg:px-8 w-full">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Activities
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Showing list of all your activities on kava
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              {/* <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Add user
              </button> */}
            </div>
          </div>
          <TableWithCheckbox
            columns={[
              {
                name: "Device IP | Device",
                accessor: "",
                render: (activity: SeeClientActivites_seeClientActivites) => (
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-10 h-10"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>

                      {/* <img
className="h-10 w-10 rounded-full"
src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
alt=""
/> */}
                    </div>
                    <div className="ml-4">
                      <div className="font-medium text-gray-900">
                        {activity?.ip_address}
                      </div>
                      <div className="text-gray-500">
                        {activity?.device_type}
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                name: "Country | City",
                accessor: "",
                render: (activity: SeeClientActivites_seeClientActivites) => (
                  <div className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                    <div className="text-gray-900">{activity?.country}</div>
                    <div className="text-gray-500">{activity?.city}</div>
                  </div>
                ),
              },
              {
                name: "Logged in on",
                accessor: "created_at",
              },
            ]}
            data={activities}
            setSelected={function (
              value: React.SetStateAction<TableData<any>>
            ): void {
              throw new Error("Function not implemented.");
            }}
            selected={[]}
            loading={loading}
            error={error}
            setNumberOfRows={() => { }}
            numberOfRows={0}
          />
        </div>
      </section>
    </Fragment>
  );
};

export default Activities;
