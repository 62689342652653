import {
  DonutChart,
  Flex,
  Toggle,
  ToggleItem,
  Bold,
  Divider,
  List,
  ListItem,
  Text,
  Title,
  SelectBox,
  SelectBoxItem,
} from "@tremor/react";

import {
  ChartPieIcon,
} from "@heroicons/react/24/outline";

import { useState } from "react";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { useRevenueByClassOFBusiness } from "hooks/useRevenue";
import { RangeType, ReportType } from "graphql/__generated__/globalTypes";
import moment from "moment";
import numeral from "numeral";
import { useAppSelector } from "app/hooks";
import { getCompanyName } from "features/users";
import _ from "lodash";

export const valueFormatter = (currency: string) => (number: number,) =>
  `${currency} ${numeral(number).format("0.0a")}`;

export default function TotalRevenuePie() {
  const [selectedView, setSelectedView] = useState("chart");
  const [currency, setCurrency] = useState("USD");
  const [broker, setBroker] = useState("visalre");
  const reinsurer = useAppSelector(getCompanyName)

  const { data, loading } = useRevenueByClassOFBusiness({
    args: {
      currency: currency,
      from: "2018-01-01",
      rangeType: RangeType.Monthly,
      re_company_name: reinsurer,
      reportType: ReportType.All,
      to: moment().endOf("year").format("YYYY-MM-DD"),
    }
  });
  return (
    <div className=" h-[600px] overflow-y-scroll mt-4 ">
      <div className="bg-white overflow-y-scroll p-5 shadow-md border border-gray-200 rounded-md">
        <Flex
          spaceX="space-x-8"
          justifyContent="justify-between"
          alignItems="items-center"
        >
          <Title>Total Revenue Per Class of Bus.</Title>
          <Toggle
            defaultValue="chart"
            color="gray"
            handleSelect={(value) => setSelectedView(value)}
          >
            <ToggleItem value="chart" icon={ChartPieIcon} />
            <ToggleItem value="list" icon={Bars3Icon} />
          </Toggle>
        </Flex>
        <Divider />
        <div className="flex items-center justify-between">
          <Text>
            <Bold>Asset Allocation</Bold>
          </Text>
          <div className="flex justify-between items-start space-x-4 py-2">
            <SelectBox
              defaultValue={broker}
              value={broker}
              onValueChange={setBroker}
              placeholder="Select..."
              icon={undefined}
              maxWidth="max-w-min"
              marginTop="mt-0"
            >
              <SelectBoxItem value={"visalre"} text="Visal Re" />
              <SelectBoxItem value={"kekre"} text="KEK Re" />
              <SelectBoxItem value={"iriskre"} text="iRisk Re" />
            </SelectBox>
            <SelectBox
              defaultValue={currency}
              value={currency}
              onValueChange={setCurrency}
              placeholder="Select..."
              icon={undefined}
              maxWidth="max-w-min"
              marginTop="mt-0"
            >
              <SelectBoxItem value={"GHS"} text="GHS" />
              <SelectBoxItem value={"USD"} text="USD" />
              <SelectBoxItem value={"EUR"} text="EUR" />
              <SelectBoxItem value={"GBP"} text="GBP" />
            </SelectBox>
          </div>
        </div>
        {selectedView === "chart" ? (
          <DonutChart
            data={data}
            showAnimation={false}
            category={broker}
            dataKey="class_of_business"
            valueFormatter={valueFormatter(currency)}
            marginTop="mt-6"
          />
        ) : (
          <>
            <Flex marginTop="mt-8" justifyContent="justify-between">
              <Text truncate={true}>
                <Bold>Stocks</Bold>
              </Text>
              <Text>Since transaction</Text>
            </Flex>
            <List marginTop="mt-4">
              {data.map((stock) => (
                <ListItem key={stock.class_of_business}>
                  <Text>{stock.class_of_business}</Text>
                  <Flex justifyContent="justify-end" spaceX="space-x-2">
                    <Text>
                      {currency} {Intl.NumberFormat("us").format(_.get(stock, broker, 0) || 0).toString()}
                    </Text>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </div>
    </div>
  );
}
