import SelectInput from 'components/select-input'
import TextInput from 'components/text-input'
import { useFormik } from 'formik'
import { classNames, tabs } from 'utils'
import { useMutation } from '@apollo/client'
import { generateClaimReport } from 'graphql/mutations/reports'
import wrapClick from 'utils/wrap-click'
import { Link } from 'react-location'
import useUrlState from 'utils/use-url-state'
import _ from 'lodash'
import { GenerateClaimsReport, GenerateClaimsReportVariables } from 'graphql/mutations/__generated__/GenerateClaimsReport'
import { useTitle } from 'react-use'
import numeral from 'numeral'

type Props = {}

const ClaimsReportsTabs = (props: Props) => {
    useTitle("Kava | Claims Reports")
    const [orderStatus, setOrderStatus] = useUrlState("brokerTab");
    const [initiate, { loading, data }] = useMutation<GenerateClaimsReport, GenerateClaimsReportVariables>(generateClaimReport)
    const form = useFormik({
        initialValues: {
            from: '',
            to: '',
            brokers: []
        },
        onSubmit: (values) => {
            initiate({
                variables: {
                    args: {
                        brokers: values.brokers,
                        from: values.from,
                        to: values.to
                    }
                }
            }).then(res => {
                setOrderStatus(res.data?.generateClaimsReport?.[0]?.name || undefined)
            }).catch(err => {
                console.log(err)
            })
        }
    })
    return (
        <div className='w-full'>
            <div className="rounded-lg  bg-white px-4 shadow w-full">
                <div className='grid gap-4  py-7 grid-cols-1 md:grid-cols-2'>
                    <div className='col-span-2'>
                        <SelectInput label='Broker(s)' multiple id={'brokers'} options={[
                            ...tabs.map(tab => ({ label: tab.name, value: tab.href }))
                        ]} {...form} />
                    </div>
                    <div>
                        <TextInput label='From' id={'from'} type='date' {...form} />
                    </div>
                    <div>
                        <TextInput label='To' id={'to'} type='date' {...form} />
                    </div>
                </div>
                <div className='pb-6'>
                    <button disabled={loading} onClick={wrapClick(form.handleSubmit)} className='bg-green-500 disabled:bg-gray-500 disabled:cursor-not-allowed text-white px-4 py-2 rounded-md'>{loading ? "Please wait ..." : "Generate Report"}</button>
                </div>
            </div>

            <div className="block">
                <div className="border-b border-gray-200 ">
                    <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
                        {data?.generateClaimsReport?.map((_orderStatus) => (
                            <Link
                                key={_orderStatus?.name}
                                search={(old) => ({
                                    ...old,
                                    brokerTab: _orderStatus?.name,
                                })}
                                className={classNames(
                                    orderStatus === _orderStatus?.name
                                        ? "border-green-500 text-green-600"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                                )}
                                aria-current={
                                    orderStatus === _orderStatus?.name ? "page" : undefined
                                }
                            >
                                {_orderStatus?.name}
                            </Link>
                        ))}
                    </nav>
                </div>
            </div>
            {data?.generateClaimsReport && (
                <div className="flex flex-1 overflow-y-auto w-full">
                    <div className="flex-1 mx-auto min-w-0   sm:py-6 overflow-hidden overflow-y-auto light flex">
                        <div className="flow-root w-full">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        Policy Number
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Cedant
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Insured
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Claim Share
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Period From
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Period To
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {_.get(_.find(data?.generateClaimsReport, el => el?.name && el?.name === orderStatus), "data", []).map((person: any, idx) => (
                                                    <tr key={idx}>
                                                        <td className="max-w-0 w-auto truncate py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {person?.policy_number}
                                                        </td>
                                                        <td className="max-w-0 w-auto truncate px-3 py-4 text-sm text-gray-500">{person.cedant}</td>
                                                        <td className="max-w-0 w-auto truncate px-3 py-4 text-sm text-gray-500">{person.insured}</td>
                                                        <td className="max-w-0 w-auto truncate px-3 py-4 text-sm text-gray-500">{numeral(person.claim_share).format("0,0.00")}</td>
                                                        <td className="max-w-0 w-auto truncate px-3 py-4 text-sm text-gray-500">{person.period_from}</td>
                                                        <td className="max-w-0 w-auto truncate px-3 py-4 text-sm text-gray-500">{person.period_to}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ClaimsReportsTabs