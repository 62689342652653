/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation } from "@apollo/client";
import { FC, Fragment, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { getOTP, userLogin, validateOTP } from "../../graphql/mutations/auth";
import {
  Login,
  LoginVariables,
  Login_login,
} from "../../graphql/mutations/__generated__/Login";
// import Comfi from "../../assets/comfi 4.svg";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useNavigate } from "react-location";
import { useAppDispatch } from "../../app/hooks";
import { authenticate } from "../../features/users";
import { GenerateOtp, GenerateOtpVariables } from "graphql/mutations/__generated__/GenerateOtp";
import { ValidateOtp, ValidateOtpVariables } from "graphql/mutations/__generated__/ValidateOtp";
import { usePage } from "hooks/usePage";

const LoginScreen: FC = () => {
  usePage("Kava | Login");
  const navigate = useNavigate();
  const [step, setstep] = useState(0)
  const [otp, setOtp] = useState("")
  const dispatch = useAppDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginVariables & { otp: string }>();
  const [login, { loading }] = useMutation<Login, LoginVariables>(userLogin);
  const [generateOtp, { loading: generating }] = useMutation<GenerateOtp, GenerateOtpVariables>(getOTP);
  const [validate, { loading: validating }] = useMutation<ValidateOtp, ValidateOtpVariables>(validateOTP);

  const onSubmit: SubmitHandler<LoginVariables & { otp: string }> = (data) => {

    if (step === 0) {
      showNotification({
        id: "login",
        loading: true,
        title: "Getting OTP",
        message: "Data will be loaded in 3 seconds, you cannot close this yet",
        autoClose: false,
        disallowClose: true,
      });
      generateOtp({
        variables: {
          args: {
            identifier: data?.input?.username || "",
          }
        }
      })
        .then((res) => {
          if (res.data?.generateOtp?.success) {
            setstep(1)
            setOtp(res.data?.generateOtp?.otp || "")
            updateNotification({
              id: "login",
              title: "Success",
              message: "OTP sent to your email",
              color: "blue",
            });
          } else {
            updateNotification({
              id: "login",
              title: "Failed",
              message: res.data?.generateOtp?.message || "Failed to send OTP",
              color: "blue",
            });
          }

        })
        .catch(() => {
          updateNotification({
            id: "login",
            title: "Error",
            message: "The user credentials were incorrect",
            color: "red",
          });
        });

      return;
    }

    if (step === 1) {
      showNotification({
        id: "login",
        loading: true,
        title: "Validating OTP",
        message: " Data will be loaded in 3 seconds, you cannot close this yet",
        autoClose: false,
        disallowClose: true,
      });
      validate({
        variables: {
          args: {
            identifier: data?.input?.username || "",
            otp: data.otp
          }
        }
      }).then((res) => {
        if (res?.data?.validateOtp?.success) {
          updateNotification({
            id: "login",
            title: "Success",
            message: res.data?.validateOtp?.message || "OTP is correct",
            color: "blue",
          });
          setstep(2)
        } else {
          updateNotification({
            id: "login",
            title: "Error",
            message: res.data?.validateOtp?.message || "The OTP was incorrect",
            color: "red",
          });
        }

      }).catch((err) => {
        updateNotification({
          id: "login",
          title: "Error",
          message: "The OTP was incorrect",
          color: "red",
        });
      })
      return;
    }


    showNotification({
      id: "login",
      loading: true,
      title: "Logging in",
      message: "Data will be loaded in 3 seconds, you cannot close this yet",
      autoClose: false,
      disallowClose: true,
    });
    login({ variables: data })
      .then((res) => {
        dispatch(authenticate(res.data?.login as Login_login));
        navigate({
          to: "/app/dashboard",
          replace: true
        });
        updateNotification({
          id: "login",
          title: "Success",
          message: "Login Successful",
          color: "blue",
        });
      })
      .catch(() => {
        updateNotification({
          id: "login",
          title: "Error",
          message: "The user credentials were incorrect",
          color: "red",
        });
      });
  };

  return (
    <Fragment>
      <div>
        {/* <img className="h-24 w-auto" src={Comfi} alt="Workflow" /> */}
        <Fragment>
          <h2 className="mt-6 text-3xl font-medium text-gray-900">
            Sign in to your account
          </h2>
        </Fragment>
      </div>
      <div className="mt-8">
        <div className="mt-6">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            {/* <!-- This example requires Tailwind CSS v2.0+ --> */}

            <div>
              <div className="flex justify-between">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
              </div>
              <div className="mt-1">
                <input
                  type="email"
                  {...register("input.username", { required: "Required" })}
                  id="email"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="you@example.com"
                  aria-describedby="email-optional"
                />
                {errors?.input?.username && (
                  <p className="text-red-400">
                    {errors?.input?.username?.message}
                  </p>
                )}
              </div>
            </div>

            {step === 1 && (
              <div>
                <div className="flex justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    OTP
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    type="text"
                    {...register("otp", { required: "Required" })}
                    id="password"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="***********"
                    aria-describedby="email-optional"
                  />
                  {errors?.input?.password && (
                    <p className="text-red-400">
                      {errors?.input?.password?.message}
                    </p>
                  )}
                </div>
              </div>
            )}
            {step === 2 && (
              <div>
                <div className="flex justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    type="password"
                    {...register("input.password", { required: "Required" })}
                    id="password"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="***********"
                    aria-describedby="email-optional"
                  />
                  {errors?.input?.password && (
                    <p className="text-red-400">
                      {errors?.input?.password?.message}
                    </p>
                  )}
                </div>
              </div>
            )}

            <div>
              <button
                type="submit"
                disabled={loading || generating || validating}
                className="w-full flex justify-center py-2 disabled:bg-gray-500 px-4 border bg-white  rounded-md shadow-sm text-sm transform transition duration-200 font-medium text-green-600 hover:text-white border-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>{" "}
                next
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginScreen;
