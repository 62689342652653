import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Login_login } from "../graphql/mutations/__generated__/Login";
import { RootState } from "../app/store";
import _ from "lodash";

export interface UserState extends Login_login {}

const initialState: UserState = {
  access_token: "",
  user: null,
  __typename: "AuthPayload",
  refresh_token: null,
  expires_in: null,
  token_type: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    authenticate: (state, { payload }: PayloadAction<Login_login>) => {
      state.user = payload.user;
      state.access_token = payload.access_token;
    },
    logout: (state) => {
      state.user = null;
      state.access_token = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { authenticate, logout } = userSlice.actions;

export const getUserName = ({ auth: { user } }: RootState) => {
  return user?.clientable?.__typename === "Insurer_associate"
    ? `${_.startCase(
        user?.clientable?.assoc_first_name?.toLowerCase() ?? ""
      )} ${_.startCase(user?.clientable?.assoc_last_name?.toLowerCase())}`
    : `${_.startCase(
        user?.clientable?.rep_first_name?.toLowerCase()
      )} ${_.startCase(user?.clientable?.rep_last_name?.toLowerCase())}`;
};

export const getCompanyName = ({ auth: { user } }: RootState) => {
  return user?.clientable?.__typename === "Insurer_associate"
    ? `${_.startCase(
        user?.clientable?.insurer?.insurer_company_name?.toLowerCase() ?? ""
      )} `
    : `${_.startCase(
        user?.clientable?.reinsurer?.re_company_name?.toLowerCase()
      )} `;
};

export default userSlice.reducer;
