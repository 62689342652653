import { useQuery } from "@apollo/client";
import { fetchTreaties } from "../graphql/queries/treaties";
import {
  FetchTreaties,
  FetchTreatiesVariables,
} from "../graphql/queries/__generated__/FetchTreaties";

export const useTreaties = (variables?: FetchTreatiesVariables) => {
  const { data, loading, error, networkStatus, fetchMore, refetch } = useQuery<
    FetchTreaties,
    FetchTreatiesVariables
  >(fetchTreaties, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return {
    treaties: data?.fetchTreaties?.data || [],
    total_treaties: data?.fetchTreaties?.total ?? 0,
    from: data?.fetchTreaties?.from,
    to: data?.fetchTreaties?.to,
    last_page: data?.fetchTreaties?.last_page ?? 1,
    total_shares: JSON.parse("{}"),
    loading,
    error,
    networkStatus,
    fetchMore,
    refetch,
  };
};
