import { ScrollArea, Pagination } from "@mantine/core";
import { FC, SetStateAction, useState } from "react";
import Loader from "../../components/Loader";
import PageHeader from "../../components/page-header";
import PageWrapper from "../../components/page-wrapper";
import Show from "../../components/show";
import TableWithCheckbox from "../../components/TableWithCheckbox/TableWithCheckbox";
import Tabs from "../../components/year-tabs";
import { BrokerTypes } from "../../graphql/__generated__/globalTypes";
import { useOffers } from "../../hooks/useOffers";
import { TableData } from "../../utils/types";
import Overview from "./components/Overview";
import { columns } from "./columns";
import { useDebounce } from "react-use";
import { usePage } from "hooks/usePage";

type Props = {};

const AllOffers: FC<Props> = (props) => {
  usePage("Kava | All Offers");
  const [year, setYear] = useState("second");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState<BrokerTypes>(BrokerTypes.VISAL);
  const [val, setVal] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  useDebounce(
    () => {
      setDebouncedValue(val);
    },
    1000,
    [val]
  );
  const {
    offers,
    loading,
    total_offers,
    total_shares,
    error,
    from,
    to,
    last_page,
  } = useOffers({
    broker: activeTab,
    perPage,
    paginateTo: page,
    searchItem: debouncedValue,
  });

  return (
    <PageWrapper>
      <PageHeader title="Facultative Offers" />
      <Overview
        stats={[
          {
            name: "Total Offers",
            value: total_offers ?? 0,
          },
          ...(total_shares
            ? Object.keys(total_shares).map((e) => ({
              name: `Total ${e}`,
              value: `${total_shares[e]}`,
              description: "Premium",
            }))
            : []),
        ]}
      />
      <Tabs
        year={year}
        setYear={setYear}
        setActiveTab={(value) => {
          setActiveTab(value);
          setPage(1);
          setVal("");
        }}
        activeTab={activeTab}
      />
      <ScrollArea>
        <section className="mt-8" aria-labelledby="gallery-heading">
          <TableWithCheckbox
            columns={columns}
            data={offers}
            searchValue={val}
            search={(value: string) => setVal(value)}
            setSelected={function (
              value: SetStateAction<TableData<any>>
            ): void {
              throw new Error("Function not implemented.");
            }}
            selected={[]}
            loading={loading}
            error={error}
            setNumberOfRows={setPerPage}
            numberOfRows={perPage}
          />
        </section>
        <section className="p-3 flex justify-between items-center">
          <span>
            Showing {from} to {to} of {total_offers} items
          </span>
          <Pagination
            total={last_page}
            page={page}
            onChange={(page) => {
              setPage(page);
            }}
          />
        </section>
      </ScrollArea>
    </PageWrapper>
  );
};

export default AllOffers;
