import { clsx } from "@mantine/core";
import { FC, useEffect } from "react";
import { subNavigation } from "../../utils";
import useUrlState from "utils/use-url-state";
import Profile from "pages/Profile";
import Account from "pages/Account";
import Activities from "pages/Activities";

type Props = {};

const Settings: FC<Props> = (props) => {
  
  const [tab, setTab] = useUrlState("orderStatus");

  useEffect(() => {
    if (!tab) {
      setTab("Profile");
    }
  }, [])

  return (
    <main className="overflow-y-scroll pb-10 lg:py-12 lg:px-8 w-full">
      <div className="lg:grid lg:grid-cols-11 lg:gap-x-5 overflow-hidden">
        <aside className="py-6 px-2 sm:px-6 col-span-2 lg:py-0 lg:px-0 ">
          <nav className="space-y-1">
            {subNavigation.map((item) => (
              <div
                key={item.name}
                // to={item.href}
                onClick={() => setTab(item.name)}
                className="group rounded-md px-3 py-2 flex items-center text-sm font-medium"
              // getActiveProps={() => ({
              //   className: "bg-gray-50 text-green-600 hover:bg-white"
              // })}
              // getInactiveProps={() => ({
              //   className: "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
              // })}
              >
                <>
                  <item.icon
                    className={clsx(
                      tab === item.name
                        ? "text-green-500"
                        : "text-gray-400 group-hover:text-gray-500",
                      "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  <span className="truncate">{item.name}</span>
                </>
              </div>
            ))}
          </nav>
        </aside>

        {/* Payment details */}

        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 w-full ">
          {tab === "Profile" && <Profile />}
          {tab === "Account" && <Account />}
          {tab === "Activities" && <Activities />}
        </div>
      </div>
    </main>
  );
};

export default Settings;
