import lodash from "lodash";
import { rankRoutes } from "react-location-rank-routes";
import { RouteProps } from "./routes";
import { store } from "../app/store";

export const withRoutePermissions = (routes: RouteProps[]) => {
  const token = store.getState().auth.access_token
  return lodash.filter(routes, (route) => true);
};
/**
 *
 * @param permissions permissions associated with the current user. pass this if you are using permissions
 * @returns a list of filtered routes
 */
const filterRoutes = () => {
  return lodash.flow([rankRoutes, withRoutePermissions]);
};

export default filterRoutes;
