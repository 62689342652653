import { gql } from "@apollo/client";


export const generateRevenueReport = gql`
mutation GenerateRevenueReport($args: ReportInput) {
  generateRevenueReport(args: $args) {
    name
    error {
      message
      status
    }
    data {
      policy_number
      cedant
      insured
      participating_percentage
      fac_premium
      share_amount
      period_from
      period_to
    }
  }
}
`

export const generatePaymentReport = gql`
mutation GeneratePaymentReport($args: ReportInput) {
  generatePaymentReport(args: $args) {
    name
    data {
      policy_number
      cedant
      insured
      payment_amount
      payment_type
      period_from
      period_to
    }
    error {
      message
      status
    }
  }
}
`

export const generateClaimReport = gql`
mutation GenerateClaimsReport($args: ReportInput) {
  generateClaimsReport(args: $args) {
    name
    data {
      policy_number
      cedant
      insured
      claim_share
      period_from
      period_to
    }
    error {
      message
      status
    }
  }
}`;