import React from "react";
import {
  FetchOffers_fetchOffers_data,
  FetchOffers_fetchOffers_data_offer_offer_claim,
} from "../../../graphql/queries/__generated__/FetchOffers";
import TableWithCheckbox from "../../../components/TableWithCheckbox/TableWithCheckbox";
import { TableData } from "../../../utils/types";
import { claimColumns } from "../columns";

type Props = {
  claims:
    | (FetchOffers_fetchOffers_data_offer_offer_claim | null)[]
    | null
    | undefined;
  offer: FetchOffers_fetchOffers_data | null;
};

const OfferClaims = ({ claims, offer }: Props) => {
  return (
    <div>
      <TableWithCheckbox
        columns={claimColumns}
        data={
          claims?.map((el) => ({
            ...el,
            claim_share: el?.offer_claim_participants?.find(
              (el) => el?.offer_participant_id === offer?.offer_participant_id
            )?.claim_share,
            offer_claim_participant_id: el?.offer_claim_participants?.find(
              (el) => el?.offer_participant_id === offer?.offer_participant_id
            )?.offer_claim_participant_id,
            offer_participant_percentage: offer?.offer_participant_percentage,
            offer_participant_id: offer?.offer_participant_id,
            offer_id: offer?.offersoffer_id,
            reinsurer_id: offer?.reinsurer?.reinsurer_id,
          })) ?? []
        }
        setSelected={function (
          value: React.SetStateAction<TableData<any>>
        ): void {
          throw new Error("Function not implemented.");
        }}
        selected={[]}
        loading={false}
        error={undefined}
        setNumberOfRows={function (value: React.SetStateAction<number>): void {
          throw new Error("Function not implemented.");
        }}
        numberOfRows={0}
      />
    </div>
  );
};

export default OfferClaims;
