import React, { useState } from "react";
import DocumentWrapper from "../../../components/document-wrapper";
import { urls } from "../../../constants";
import { BrokerTypes } from "../../../graphql/__generated__/globalTypes";
import { generateURlData } from "../../../utils";
import { useAppSelector } from "../../../app/hooks";

type Props = {
  item: any;
};

const ClaimsActions = ({ item }: Props) => {
  const { broker } = useAppSelector((state) => state.offers);
  const [showClaimsHistory, setShowClaimsHistory] = useState(false);
  return (
    <>
      <div className="flex items-center">
        <button
          onClick={() => setShowClaimsHistory(true)}
          type="button"
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          View claims history
        </button>
      </div>

      <DocumentWrapper
        show={showClaimsHistory}
        setShow={setShowClaimsHistory}
        url={`${
          urls[broker || BrokerTypes.KEK]
        }/claimdebitnote/${generateURlData({
          offer_id: item?.offer_id,
          reinsurer_id: item?.reinsurer_id,
          offer_claim_participant_id: item?.offer_claim_participant_id,
          emp_id: "1",
        })}`}
      />
    </>
  );
};

export default ClaimsActions;
