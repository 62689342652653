import { gql } from "@apollo/client";

export const fetchTreaties = gql`
  query FetchTreaties($broker: BrokerTypes!, $paginateTo: Int, $perPage: Int) {
    fetchTreaties(broker: $broker, paginateTo: $paginateTo, perPage: $perPage) {
      current_page
      first_page_url
      from
      to
      last_page
      last_page_url
      next_page_url
      per_page
      total
      data {
        treaty_participation_id
        treaty_participation_percentage
        treaty {
          treaty_id
          treaty_participation_percentage
          treaty_reference
          currency
          layer_limit
          insurer {
            insurer_id
            insurer_company_name
          }
          treaty_accounts {
            treaty_account_id
            account_periods
          }
          treaty_program {
            treaty_program_id
            treaty_name
            treaty_type
          }
          order_hereon
          treaty_deduction {
            treaty_period_from
            treaty_period_to
          }
        }
      }
    }
  }
`;
