import { BuildingOfficeIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useAppSelector } from "../../../app/hooks";
import { getCompanyName, getUserName } from "../../../features/users";
import { dashboardGreeting } from "../../../utils/helpers";
import { getUserInitials } from "../../../utils";

type Props = {};

const ProfileSection = (props: Props) => {
  const { user } = useAppSelector((state) => state.auth);
  const name = useAppSelector(getUserName);
  const company = useAppSelector(getCompanyName);
  return (
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 lg:mx-auto  lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="min-w-0 flex-1">
            {/* Profile */}
            <div className="flex items-center">
              <div className="hidden h-16 w-16 rounded-full font-bold sm:flex text-green-800 bg-green-200 items-center justify-center">
                {getUserInitials(user as any)}
              </div>
              <div>
                <div className="flex items-center">
                  <div className="h-16 w-16 font-bold  sm:hidden text-green-800 flex items-center justify-center bg-green-200 rounded-full">
                    {getUserInitials(user as any)}
                  </div>
                  {/* <img
                    className="h-16 w-16 rounded-full sm:hidden"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                    alt=""
                  /> */}
                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                    Good {dashboardGreeting()}, {name}
                  </h1>
                </div>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dt className="sr-only">Company</dt>
                  <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                    <BuildingOfficeIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    {company}
                  </dd>
                  <dt className="sr-only">Account status</dt>
                  <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                    <CheckCircleIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                      aria-hidden="true"
                    />
                    Verified account
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            {/* <button
              type="button"
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
            >
              Add money
            </button> */}
            <a
              type="button"
              href="https://forms.gle/VxoaH9mWjVv7VJcW8"
              target="_blank"
              // onClick={() => alert("Send money")}
              className="inline-flex items-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
            >
              Share your experience
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;
