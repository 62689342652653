/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BrokerTypes {
  IRISK = "IRISK",
  KEK = "KEK",
  VISAL = "VISAL",
}

export enum MockPaymentStatus {
  PAID = "PAID",
  PARTPAYMENT = "PARTPAYMENT",
  UNPAID = "UNPAID",
}

export enum RangeType {
  Monthly = "Monthly",
  Yearly = "Yearly",
}

export enum ReportType {
  All = "All",
  Outstanding = "Outstanding",
  Paid = "Paid",
}

export interface LoginInput {
  username: string;
  password: string;
}

export interface OtpInput {
  identifier: string;
}

export interface OtpValidationInput {
  identifier: string;
  otp: string;
}

export interface ReportInput {
  from?: string | null;
  to?: string | null;
  brokers?: (BrokerTypes | null)[] | null;
}

export interface RevenueReportInput {
  re_company_name: string;
  from: string;
  to: string;
  currency: string;
  rangeType: RangeType;
  reportType: ReportType;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
