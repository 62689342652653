import { useQuery } from "@apollo/client";
import { clientActivities } from "../graphql/queries/offers";
import { SeeClientActivites } from "../graphql/queries/__generated__/SeeClientActivites";

export const useActivities = () => {
  const { data, loading, error, fetchMore, networkStatus } =
    useQuery<SeeClientActivites>(clientActivities, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    });

  return {
    activities: data?.seeClientActivites || [],
    // total_offers: data?.seeClientActivites? ?? 0,
    // total_shares: JSON.parse("{}"),
    loading,
    fetchMore,
    networkStatus,
    error,
  };
};
