import { Fragment, useState } from "react";
import { Menu } from "@mantine/core";
import DocumentWrapper from "../../../components/document-wrapper";
import { BrokerTypes } from "../../../graphql/__generated__/globalTypes";
import { useAppSelector } from "../../../app/hooks";
import { FetchTreaties_fetchTreaties_data } from "../../../graphql/queries/__generated__/FetchTreaties";
import { Alert, Dialog, RadioGroup } from "evergreen-ui";
import { getFlexibleName, getUrl } from "../../../utils/helpers";
import Show from "../../../components/show";
import _ from "lodash";

type Props = {
  treaty: FetchTreaties_fetchTreaties_data | null;
};

const TreatyActionsMenu = ({ treaty }: Props) => {
  const items = JSON.parse(treaty?.treaty?.layer_limit ?? "[]") as any[];
  const { broker } = useAppSelector((state) => state.offers);
  const [openCreditNote, setOpenCreditNote] = useState(false);
  const [showPlacingSlip, setShowPlacingSlip] = useState(false);
  const [showQuarters, setShowQuarters] = useState(false);
  const [showLimits, setShowLimits] = useState(false);
  const [treaty_account_id, setTreaty_account_id] = useState(
    _.first(treaty?.treaty?.treaty_accounts)?.treaty_account_id ??
      _.first(items)?.uuid
  );

  const isProp = treaty?.treaty?.treaty_program?.treaty_type === "PROPORTIONAL";

  const isNotEmptyAccounts = !_.isEmpty(treaty?.treaty?.treaty_accounts);
  const isNotEmptyLimits = !_.isEmpty(treaty?.treaty?.layer_limit);

  const layer = JSON.parse(treaty?.treaty?.layer_limit ?? "")?.find(
    (el: any) => el.uuid === treaty_account_id
  );

  const layerNumber = JSON.parse(treaty?.treaty?.layer_limit ?? "")?.findIndex(
    (el: any) => el.uuid === treaty_account_id
  );

  return (
    <Fragment>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          {/* <Button>Toggle menu</Button> */}
          <button
            type="button"
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Actions
          </button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>Actions</Menu.Label>
          <Menu.Item
            onClick={() =>
              isProp ? setShowQuarters(true) : setShowLimits(true)
            }
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-green-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
            }
          >
            View Closing Slip
          </Menu.Item>
          <Menu.Item
            onClick={() => setShowPlacingSlip(true)}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-green-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
            }
          >
            View placing slip
          </Menu.Item>
          <Menu.Item
            // onClick={() => setOpenPayments(true)}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-green-400"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                />
              </svg>
            }
          >
            View Payments
          </Menu.Item>
          {/* <Menu.Item
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-green-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
            }
          >
            Issue Guarantee Note
          </Menu.Item> */}
        </Menu.Dropdown>
      </Menu>

      <Dialog
        isShown={showQuarters}
        title="Select Quarters"
        onCloseComplete={() => setShowQuarters(false)}
        confirmLabel="Continue"
        onConfirm={() =>
          isNotEmptyAccounts ? setOpenCreditNote(true) : setShowQuarters(false)
        }
      >
        {/* {treaty_account_id} */}
        <Show if={isNotEmptyAccounts}>
          <RadioGroup
            label="Quarters"
            size={16}
            defaultChecked={false}
            value={treaty_account_id}
            options={
              treaty?.treaty?.treaty_accounts?.map((item) => ({
                label: getFlexibleName(item?.account_periods ?? ""),
                value: item?.treaty_account_id ?? "",
                checked: item?.treaty_account_id === treaty_account_id,
              })) ?? []
            }
            onChange={(event) => setTreaty_account_id(event.target.value)}
          />
        </Show>
        <Show if={!isNotEmptyAccounts}>
          <Alert>No Quarters have been filed for this Treaty by Broker</Alert>
        </Show>
      </Dialog>

      <Dialog
        isShown={showLimits}
        title="Select layer"
        onCloseComplete={() => setShowLimits(false)}
        confirmLabel="Continue"
        onConfirm={() =>
          isNotEmptyLimits ? setOpenCreditNote(true) : setShowLimits(false)
        }
      >
        <Show if={isNotEmptyLimits}>
          <RadioGroup
            label="Layers"
            size={16}
            value={treaty_account_id}
            options={
              JSON.parse(treaty?.treaty?.layer_limit ?? "[]")?.map(
                (item: any, index: number) => ({
                  label: "Layer " + (index + 1),
                  value: item?.uuid ?? "",
                })
              ) ?? []
            }
            onChange={(event) => setTreaty_account_id(event.target.value)}
          />
        </Show>
        <Show if={!isNotEmptyLimits}>
          <Alert>No Quarters have been filed for this Treaty by Broker</Alert>
        </Show>
        {/* {treaty_account_id} */}
      </Dialog>

      <DocumentWrapper
        show={openCreditNote}
        setShow={setOpenCreditNote}
        url={getUrl(
          isProp
            ? {
                participant_id: treaty?.treaty_participation_id,
                treaty_account_id: treaty_account_id,
                type: 0,
                emp_id: 1,
              }
            : {
                participant_id: treaty?.treaty_participation_id,
                limit: layer?.limit,
                layer: layerNumber + 1,
                uuid: layer?.uuid,
                m_and_d_premium: layer?.m_and_d_premium,
                total_participation_percentage:
                  treaty?.treaty_participation_percentage,
                installment_type: layer?.installment_type,
              },
          treaty?.treaty?.treaty_program?.treaty_type === "PROPORTIONAL"
            ? "generate_treaty_credit_note"
            : "treaty_np_credit_note",
          broker as BrokerTypes
        )}
      />

      <DocumentWrapper
        show={showPlacingSlip}
        setShow={setShowPlacingSlip}
        url={getUrl(
          {
            treaty_id: treaty?.treaty?.treaty_id,
            doc_type: "PLACING",
            emp_id: "1",
          },
          "treaty_placing_cover_note",
          broker as BrokerTypes
        )}
      />
    </Fragment>
  );
};

export default TreatyActionsMenu;
