import PageHeader from 'components/page-header'
import PageWrapper from 'components/page-wrapper'
import { FC } from 'react'
import { Link } from 'react-location'
import { classNames } from 'utils'
import useUrlState from 'utils/use-url-state'
import RevenueReportsTabs from './tabs/revenue'
import PaymentsReportsTabs from './tabs/payments'
import ClaimsReportsTabs from './tabs/claims'
import { useTitle } from 'react-use'
import { usePage } from 'hooks/usePage'

type Props = {}

const tabs = [
    { name: "Payments Report", href: "payment" },
    { name: "Claims Report", href: "claims" },
];

const ReportsPage: FC<Props> = (props) => {
    usePage("Kava | Reports")
    const [orderStatus] = useUrlState("orderStatus");
    return (
        <PageWrapper>
            <PageHeader title="Reports" />
            <div className="block">
                <div className="border-b border-gray-200 ">
                    <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
                        <Link
                            search={(old) => ({
                                ...old,
                                orderStatus: undefined,
                            })}
                            className={classNames(
                                !orderStatus
                                    ? "border-green-500 text-green-600"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                            )}
                            aria-current={!orderStatus ? "page" : undefined}
                        >
                            Revenue Report
                        </Link>
                        {tabs.map((_orderStatus) => (
                            <Link
                                key={_orderStatus.name}
                                search={(old) => ({
                                    ...old,
                                    orderStatus: _orderStatus.href,
                                })}
                                className={classNames(
                                    orderStatus === _orderStatus.href
                                        ? "border-green-500 text-green-600"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                                )}
                                aria-current={
                                    orderStatus === _orderStatus.href ? "page" : undefined
                                }
                            >
                                {_orderStatus.name}
                            </Link>
                        ))}
                        {/* <div className="h-6 w-px bg-gray-300" /> */}
                    </nav>
                </div>
            </div>
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0   sm:py-6 overflow-hidden overflow-y-auto light flex">
                    {!orderStatus && <RevenueReportsTabs />}
                    {orderStatus === "payment" && <PaymentsReportsTabs />}
                    {orderStatus === "claims" && <ClaimsReportsTabs />}
                </div>
            </div>
        </PageWrapper>
    )
}

export default ReportsPage