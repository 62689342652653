import { FC } from "react";
import { classNames } from "utils";
import Select from "react-select"; // Import ValueType from react-select
import _ from "lodash";

interface Option {
    label: string;
    value: any;
}

interface SelectInputProps {
    id: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    values: any;
    errors?: any;
    touched?: any;
    optionsLoading?: boolean;
    setFieldValue: any;
    setFieldTouched: any;
    setFieldError: any;
    options: Option[];
    labelHidden?: boolean;
    multiple?: boolean;
}

const SelectInput: FC<SelectInputProps> = ({
    id,
    values,
    setFieldValue,
    setFieldTouched,
    placeholder,
    label,
    errors,
    touched,
    required,
    labelHidden,
    disabled,
    options,
    multiple,
}) => {

    const selectedValue = multiple
        ? (values[id] || []).map((val: string) =>
            options.find((o) => o.value === val)
        )
        : options.find((o) => o.value === values[id]);


    const handleChange = (
        selectedOptions: any
    ): void => {
        if (multiple && Array.isArray(selectedOptions)) {
            const selectedValues = selectedOptions.map((option) => option.value);
            setFieldTouched(id, true);
            setFieldValue(id, selectedValues);
        } else {
            const selectedValue = selectedOptions ? selectedOptions.value : null;
            setFieldTouched(id, true);
            setFieldValue(id, selectedValue);
        }
    };

    return (
        <>
            {!labelHidden && (
                <label htmlFor={id} className="block text-sm font-medium text-gray-700">
                    {label} {required ? "*" : ""}
                </label>
            )}
            <div className={classNames(labelHidden ? "" : "mt-1", "relative w-auto")}>
                <Select
                    name={id}
                    id={id}
                    value={selectedValue} // Find the selected option based on values[id]
                    onChange={(selectedOption) => handleChange(selectedOption)} // Pass the selectedOption to handleChange
                    isDisabled={disabled}
                    isMulti={multiple} // Use isMulti based on the multiple prop
                    options={options}
                    placeholder={placeholder}
                    className={classNames(
                        _.get(values, id, "") === "" ? "font-light text-xs" : "text-sm",
                        _.get(errors, id) && _.get(touched, id)
                            ? "focus:ring-red-500 focus:border-red-500 border-red-600"
                            : "focus:ring-primary-500 focus:border-primary-500 border-gray-300",
                        disabled ? "cursor-not-allowed bg-gray-100" : "",
                        "shadow-sm block w-full z-30 rounded-md placeholder:font-light placeholder:text-xs h-[38px]"
                    )}
                />
            </div>
            {_.get(errors, id) && _.get(touched, id) ? (
                <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
                    {_.get(errors, id)}
                </p>
            ) : null}
        </>
    );
};

export default SelectInput;