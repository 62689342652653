import { useQuery } from "@apollo/client";
import { fetchOffers } from "../graphql/queries/offers";
import {
  FetchOffers,
  FetchOffersVariables,
} from "../graphql/queries/__generated__/FetchOffers";

export const useOffers = (variables?: FetchOffersVariables) => {
  const { data, loading, error, fetchMore, networkStatus } = useQuery<
    FetchOffers,
    FetchOffersVariables
  >(fetchOffers, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  return {
    offers: data?.fetchOffers?.data || [],
    total_offers: data?.fetchOffers?.total ?? 0,
    from: data?.fetchOffers?.from,
    to: data?.fetchOffers?.to,
    last_page: data?.fetchOffers?.last_page ?? 1,
    total_shares: JSON.parse("{}"),
    loading,
    fetchMore,
    networkStatus,
    error,
  };
};
