import { ReactElement } from "react";
import { ReactLocation, MakeGenerics } from "react-location";
import { parseSearch, stringifySearch } from "react-location-jsurl";

export type LocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
    fromDate?: string;
    toDate?: string;
    page?: number;
    pageSize?: number;
    search?: string;
    amount?: string;
    sort?: string;
    from?: string;
    modal?:
    | "create"
    | "update"
    | "view"
    | "configure"
    | "calculate"
    | "assign"
    | "schedule"
    | "send"
    | "resolve"
    | "approve"
    | "reject"
    | "reassign"
    | "export"
    | "import"
    | "investigate"
    | "remove"
    | "alert"
    | "assignPercentage"
    | "close";
    id?: string;
    payment_id?: string;
    region?: string;
    offer?: string;
    employee?: string;
    insurer?: string;
    role?: "SuperAdmin" | "Admin" | "Supervisor" | "Reader";
    month?: number;
    year?: number;
    customerType?: string;
    view?: "day" | "week" | "month" | "quarter" | "year" | "custom";
    orderStatus?: string;
    orderType?: string;
    token?: string;
    viewType: "grid" | "list";
    layer?: string;
    layers?: string;
    treaty_type?: string;
  };
  Params: {
    region?: string;
    district?: string;
    offer?: string;
    round?: string;
    plot?: string;
    itinerary?: string;
    tariffClass?: string;
    readingSet?: string;
    insurer?: string;
    treaty?: string;
    offer_participant?: string;
    reInsurer?: string;
    endorsement?: string;
    treaty_program?: string;
  };
  RouteMeta: {
    layout?: "App" | "Auth";
    breadcrumb: (params: LocationGenerics["Params"]) => ReactElement;
    section?: "General" | "Structure" | "Configuration" | "Users & Profiles";
  };
}>;

const location = new ReactLocation({
  parseSearch,
  stringifySearch,
});

export default location;
