import { gql } from "@apollo/client";

export const fetchOffers = gql`
  query FetchOffers(
    $broker: BrokerTypes!
    $paginateTo: Int
    $perPage: Int
    $searchItem: String
  ) {
    fetchOffers(
      broker: $broker
      paginateTo: $paginateTo
      perPage: $perPage
      searchItem: $searchItem
    ) {
      current_page
      first_page_url
      from
      to
      last_page
      per_page
      total
      data {
        offersoffer_id
        offer_participant_percentage
        offer_amount
        participant_fac_premium
        participant_fac_sum_insured
        offer_participant_payment {
          offer_payment_amount
          paid_details
          date_of_distribution
          created_at
        }
        offer {
          offer_id
          insurersinsurer_id
          insurance_company_name
          payment_status
          insurer {
            insurer_company_name
            insurer_id
          }
          expiry_status
          offer_claim {
            offer_claim_id
            claim_amount
            claim_date
            offer_claim_participants {
              offer_claim_participant_id
              offer_participant_id
              claim_share
            }
          }
        }
        offer_detail {
          offersoffer_id
          insured_by
          period_of_insurance_from
          period_of_insurance_to
          currency
          policy_number
        }
        offer_extra_charges {
          commission_amount
          withholding_tax
          nic_levy
          brokerage_amount
          brokerage
          withholding_tax_amount
          nic_levy_amount
          commission
        }
        reinsurer {
          reinsurer_id
          re_company_name
          re_company_email
          re_company_website
        }
        offer_participant_id
      }
    }
  }
`;

export const clientActivities = gql`
  query SeeClientActivites {
    seeClientActivites {
      ip_address
      device_type
      country_code
      city
      region
      country
      created_at
    }
  }
`;
export const revenuePie = gql`
query RevenueReportApi($args: RevenueReportInput) {
  revenueReportApi(args: $args) {
    name
    visalre
    kekre
    iriskre
  }
}
`;
export const revenueReportClassofBusiness = gql`
query RevenueReportClassofBusiness($args: RevenueReportInput) {
  revenueReportClassofBusiness(args: $args) {
    class_of_business
    kekre
    visalre
    iriskre
  }
}
`;
